import PropTypes from 'prop-types';
import { useCallback } from 'react';

import Icon from '../../components/Icon';

const CloseButton = ({ onClick }) => {
  const onButtonClick = useCallback(
    (event) => {
      event.stopPropagation();
      onClick();
    },
    [onClick],
  );

  return (
    <button
      className="mr-3 appearance-none cursor-pointer"
      type="button"
      onClick={onButtonClick}
    >
      <Icon className="h-4 w-4 opacity-40" icon="close" />
    </button>
  );
};
CloseButton.propTypes = {
  onClick: PropTypes.func,
};

CloseButton.defaultProps = {
  onClick: () => {},
};

export default CloseButton;
