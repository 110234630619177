import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMount } from 'react-use';

import VersionToastMessage from '../../enums/VersionToastMessage';
import UpdateFailedMultipleTabsOpenedError from '../../errors/UpdateFailedMultipleTabsOpenedError';
import useCustomToast from '../../hooks/useCustomToast';

const toastId = 'version-toast';

const VersionToastHandler = () => {
  const { toastInfo } = useCustomToast();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [pendingUpdateToast, setPendingUpdateToast] = useState(null);

  useMount(() => {
    // notify the service worker registration handler that the toast handler is ready
    window.dispatchEvent(new CustomEvent('version-toast-handler-ready'));
  });

  const getMessage = useCallback(
    (type) => {
      switch (type) {
        case VersionToastMessage.NewVersionAvailableUpdateNow: {
          return t(
            'New version of the app is available. Click here to update to the latest version. The app will refresh.',
          );
        }

        case VersionToastMessage.CloseOtherTabs: {
          return t(
            'Please close other tabs and click here to update to the newest version.',
          );
        }

        default:
          throw new Error(`Unknown message type: ${type}`);
      }
    },
    [t],
  );

  const showPendingUpdateToast = useCallback(
    (event) => {
      const isVisible = toast.isActive(toastId);
      if (isVisible) {
        return;
      }

      toastInfo(getMessage(VersionToastMessage.NewVersionAvailableUpdateNow), {
        // prevent any auto close or close handling. we'll do this manually based on update progress
        autoClose: false,
        closeOnClick: false,
        draggable: false,

        // main handler for clicking on the update text, essentially applying the update
        onClick: () => {
          event.detail
            .onClick()
            // a message to service worker with "skipWaiting" resolves this promise...
            // this means we applied the update and can close the toast
            .then(() => {
              toast.dismiss(toastId);
              // toast is not pending anymore. move it from the pending state
              setPendingUpdateToast(null);
            })
            .catch((err) => {
              if (err instanceof UpdateFailedMultipleTabsOpenedError) {
                toast.update(toastId, {
                  render: getMessage(VersionToastMessage.CloseOtherTabs),
                });
              }
            });
        },
        onCloseClick: () => {
          // only dismiss toast, dont apply update.
          // pending toast will be shown again on next route change because the update is not yet applied
          toast.dismiss(toastId);
        },
        toastId,
      });
    },
    [getMessage, toastInfo],
  );

  // Show pending toast on route change.
  // this is a case if the user only dismisses the toast, without "applying" the update
  useEffect(() => {
    if (pathname && pendingUpdateToast) {
      showPendingUpdateToast(pendingUpdateToast);
    }
  }, [pathname, pendingUpdateToast, showPendingUpdateToast]);

  useEffect(() => {
    if (pendingUpdateToast) {
      showPendingUpdateToast(pendingUpdateToast);
    }
  }, [pendingUpdateToast, showPendingUpdateToast]);

  // Listener for toast events
  useEffect(() => {
    const handleToast = (event) => {
      setPendingUpdateToast(event);
    };

    window.addEventListener('show-new-version-toast', handleToast);

    return () => {
      window.removeEventListener('show-new-version-toast', handleToast);
    };
  }, [getMessage, toastInfo]);
};

VersionToastHandler.propTypes = {
  children: PropTypes.node,
};

VersionToastHandler.defaultProps = {
  children: undefined,
};

export default VersionToastHandler;
