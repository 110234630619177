import { useCallback } from 'react';
import { toast } from 'react-toastify';

import NotificationCloseButton from '../app/Notifications/NotificationCloseButton';
import Icon from '../components/Icon';

const useCustomToast = () => {
  const toastSuccess = useCallback((message, options = {}) => {
    const { onCloseClick } = options;

    return toast.success(message, {
      icon: (
        <Icon
          data-test="toast-icon"
          className="h-4 w-4"
          icon="checkmarkCircleFilled"
        />
      ),
      closeButton: ({ closeToast }) => (
        // enables handler for click on button, without closing
        <NotificationCloseButton onClick={onCloseClick || closeToast} />
      ),
      ...options,
    });
  }, []);

  const toastError = useCallback((message, options = {}) => {
    const { onCloseClick } = options;
    return toast.error(message, {
      icon: <Icon data-test="toast-icon" className="h-4 w-4" icon="report" />,
      closeButton: ({ closeToast }) => (
        // enables handler for click on button, without closing
        <NotificationCloseButton onClick={onCloseClick || closeToast} />
      ),
      ...options,
    });
  }, []);

  const toastWarning = useCallback((message, options = {}) => {
    const { onCloseClick } = options;
    return toast.warning(message, {
      icon: <Icon data-test="toast-icon" className="h-4 w-4" icon="warning" />,
      closeButton: ({ closeToast }) => (
        // enables handler for click on button, without closing
        <NotificationCloseButton onClick={onCloseClick || closeToast} />
      ),
      ...options,
    });
  }, []);

  const toastInfo = useCallback((message, options = {}) => {
    const { onCloseClick } = options;

    return toast.info(message, {
      icon: (
        <Icon data-test="toast-icon" className="h-4 w-4" icon="infoFilled" />
      ),
      closeButton: ({ closeToast }) => (
        // enables handler for click on button, without closing
        <NotificationCloseButton onClick={onCloseClick || closeToast} />
      ),
      ...options,
    });
  }, []);

  return { toastError, toastInfo, toastSuccess, toastWarning };
};

export default useCustomToast;
